import axios from 'axios';
import Dexie from 'dexie';

export const CLIENT_API = 'https://api.moncefjouets.com/api/v1';
export const CLIENT_API_V2 = 'https://api.moncefjouets.com/api/v2';

export const db = new Dexie('Auth');
db.version(1).stores({
  user: 'id',
});

export const api = axios.create({
  baseURL: CLIENT_API,
});

export const apiV2 = axios.create({
  baseURL: CLIENT_API_V2,
});
export const clientApi = axios.create({
  baseURL: CLIENT_API,
});
export const resetAuthToken = async token => {
  if (token) {
    clientApi.defaults.headers.Authorization = `bearer ${token.trim()}`;
  } else {
    clientApi.defaults.headers.Authorization = null;
  }
};
