/* eslint-disable max-lines */
const strings = {
  welcome: 'Bienvenue sur',
  elBusines: 'ElBusiness',

  signUpFormHeader: 'Remplissez le formulaire pour commencer',
  signUpEmailLabel: 'Adresse Email',
  signUpEmailPlaceholder: 'Adresse Email',
  signUpLastNameLabel: 'Votre nom',
  signUpLastNamePlaceholder: 'Nom',
  signUpFirstNameLabel: 'Votre prénom',
  signUpFirstNamePlaceholder: 'Prénom',
  signUpCompanyNameLabel: 'Votre entreprise',
  signUpCompanyNamePlaceholder: 'Entreprise',
  signUpPhoneNumberLabel: 'Votre N° de téléphone',
  signUpPhoneNumberPlaceholder: 'Téléphone',
  signUpCountryLabel: 'Votre pays',
  signUpCountryPlaceholder: 'Votre pays',
  signUpTypeLabel: 'Votre type',
  signUpTypePlaceholder: 'Votre type',
  signUpPasswordLabel: 'Mot de passe',
  signUpPasswordPlaceholder: '••••••••',
  signUpPasswordConfirmationLabel: 'Confirmer votre mot de passe',
  signUpPasswordConfirmationPlaceholder: '••••••••',
  signUpAvatarLabel: 'Votre photo de profil',
  signUpLoginQuestion: 'Vous avez un compte?',
  signIn: 'connectez vous',
  signUp: 'Inscrivez-vous',
  forgotPassword: 'Mot de passe oublié?',
  signInSignUpQuestion: "Vous n'avez pas de compte?",
  start: 'Commencer',
  addAnnouncementFormTitle: 'Créer une annonce',
  addAnnouncementFormHeader:
    'Veuillez remplir le formulaire suivant afin de créer votre annonce',
  addAnnouncementTitleLabel: 'Titre',
  addAnnouncementTitlePlaceholder: 'Titre',
  addAnnouncementDescriptionLabel: 'Description',
  addAnnouncementDescriptionPlaceholder: 'Description',
  addAnnouncementCategoryLabel: 'Categorie',
  addAnnouncementCategoryPlaceholder: 'Categorie',
  addAnnouncementSubCategoryLabel: 'Sous-Categorie',
  addAnnouncementSubCategoryPlaceholder: 'Sous-Categorie',
  addAnnouncementDurationLabel: 'Durée',
  addAnnouncementDurationPlaceholder: '90',
  addAnnouncementDurationUnit: 'Jours',
  addAnnouncementCountryLabel: 'Pays',
  addAnnouncementCountryPlaceholder: 'Pays',
  addAnnouncementRegionLabel: 'Région',
  addAnnouncementRegionPlaceholder: 'Région',
  addAnnouncementAddressLabel: 'Adresse',
  addAnnouncementAddressPlaceholder: 'Adresse',
  addAnnouncementExtraFieldTitleLabel: 'Titre',
  addAnnouncementExtraFieldTitlePlaceholder: 'Titre',
  addAnnouncementExtraFieldValueLabel: 'Valeur',
  addAnnouncementExtraFieldValuePlaceholder: 'Valeur',
  addAnnouncementExtraFieldUnitLabel: 'Untité',
  addAnnouncementExtraFieldUnitPlaceholder: 'Unité',
  addAnnouncementAddExtraField: "Ajouter plus d'informations",
  addAnnouncementRemoveExtraField: 'Annuler',
  imageUploadDefaultMessage: 'Choisissez au moins une image',
  imageUploadRemoveImage: 'Annuler',
  addAnnouncementSubmit: 'Ajouter votre annonce',
  headerStrings: {
    entreprisesEtCommerces: 'Entreprise et Commerce',
    immobilierPro: 'Immobilier Professionnel',
    investissementEtPart: 'Investissement et Partenariat',
    seConnecter: 'Se Connecter',
    seDeconnecter: 'Se Déconnecter',
  },
  addAnnouncementUnitLabel: 'Unité',
  addAnnouncementUnitPlaceholder: 'Unité',
  addAnnouncementAnnualIncreaseLabel: 'Augmentation annuelle',
  addAnnouncementAnnualIncreasePlaceholder: '3',
  addAnnouncementSituationLabel: 'Situation',
  addAnnouncementSituationPLaceholder: 'Situation',
  addAnnouncementSurfaceLabel: 'Surface',
  addAnnouncementSurfacePLaceholder: 'Surface',
  addAnnouncementSurfaceUnit: 'm²',
  addAnnouncementActivityLabel: 'Activité',
  addAnnouncementActivityPLaceholder: 'Activité',
  addAnnouncementSubActivityLabel: 'Sous-Activité',
  addAnnouncementSubActivityPLaceholder: 'Sous-Activité',
  addAnnouncementRealEstateTypeLabel: 'Type de bien',
  addAnnouncementRealEstateTypePLaceholder: 'Type de bien',
  addAnnouncementOpportunityLabel: 'Opportunité',
  addAnnouncementOpportunityPLaceholder: 'Opportunité',
  addAnnouncementSubOpportunityLabel: 'Type',
  addAnnouncementSubOpportunityPLaceholder: 'Type',
  announcementViewsPlural: 'Vues',
  announcementViewsSingular: 'Vue',
  announcementMoreInformations: 'Informations supplémentaires',
  filterStrings: {
    dateLabel: 'Créée après le',
    searchSubmit: 'Rechercher',
    price: 'Prix',
    searchLabel: 'Recherche',
    searchPlaceholder: 'Recherche',
    categoryLabel: 'Categorie',
  },
  announcementCard: {
    details: 'Détails',
    postedOn: 'Publiée le',
    edit: 'Modifier',
    boost: 'Boost',
    expireLe: 'Sponsorisation expire',
  },
  announcementID: 'ID',
  announcements: 'Annonces',
  informations: 'Informations',
  description: 'Description',
  similarAnnouncements: 'Annonces similaires',
  userProfile: {
    maxFilesReached:
      "Vous avez atteint le maximum nombre d'images, veuillez en supprimer afin de pouvoir ajouter de nouvelles photos.",
    myProfile: 'Mon profil',
    saveChanges: 'Enregistrer les modifications',
    generalInformation: 'Informations générales',
    reorderPhotos: 'Ordre des images',
    reorderPhotosText: 'Glissez les images par ordre de priorité',
    addPhotos: 'Ajouter des photos',
    userInformations: 'Informations personnelles',
    children: 'Modifier la partie de vos enfants',
    changePassword: 'Modification de mot de passe',
    userAnnouncements: 'Annonces publiées',
    noAnnouncements: "Vous n'avez pas encore publié d'annonce",
    oldPasswordLabel: 'Ancien mot de passe',
    firstName: 'Prénom',
    lastName: 'Nom',
    phoneNumber: 'N° de téléphone',
    companyName: 'Entreprise',
    userType: "Type d'utilisateur",
    country: 'Pays',
    announcementNumber: "N° d'annonces",
    accountCreatedAt: 'Compte créé',
    accountCreatedAtPrefix: 'Le',
    credits: 'Crédits El Business',
  },
  boostAnnouncement: {
    confirm: 'Confirmer',
    availableCredits: 'crédits disponibles',
    title: 'Boostez votre annonce',
    tier1: {
      title: 'Tier 1',
      subTitle:
        'El formule , vous aurez droit à une présence sur les bannières El Business &   ',
      type1: {
        feature1: '7 jours',
        feature2: '2$ Ad',
      },
      type2: {
        feature1: '15 jours',
        feature2: '4$ Ad',
      },
    },
    tier2: {
      title: 'Tier 2',
      subTitle:
        'El formule , vous aurez droit à une présence dans les résultas de recherche El Business & Partages facebook',
      type1: {
        feature1: '7 jours',
        feature2: 'Facebook share',
      },
      type2: {
        feature1: '15 jours',
        feature2: 'Facebook share',
      },
    },
  },
};

export default strings;
