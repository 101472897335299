import React, { useState, useEffect } from 'react';
// import ImageAsync from 'react-image-async';
import PropTypes from 'prop-types';
import loadImage from 'image-promise';
import LoaderComponent from 'components/Loader';

const ImageAsyncComponent = ({
  image,
  fallback = '/assets/images/logo1.png',
}) => {
  const [src, setSrc] = useState('/assets/images/logo1.png');
  const [loading, setLoadingImage] = useState(true);

  useEffect(() => {
    setLoadingImage(true);
    loadImage(image)
      .then(() => {
        setSrc(image);
        setLoadingImage(false);
      })
      .catch(() => {
        loadImage(fallback)
          .then(() => {
            setSrc(fallback);
            setLoadingImage(false);
          })
          .catch(() => {
            setSrc('/assets/images/logo1.png');
            setLoadingImage(false);
          });
      });
  }, [image, fallback]);

  return (
    <div className="position-relative">
      {loading && (
        <div
          style={{
            position: 'absolute',
            left: '40%',
            top: '30%',
            zIndex: '99',
          }}
        >
          <LoaderComponent />
        </div>
      )}

      <figure
        style={loading ? { filter: 'blur(7px)' } : undefined}
        className="effect-sadie "
      >
        <div className="dark-overlay">
          <div
            className="image-preview"
            style={{
              backgroundImage: `url(${src})`,
              backgroundSize: 'contain',
            }}
          />
        </div>
      </figure>
    </div>
  );
};

ImageAsyncComponent.propTypes = {
  image: PropTypes.string,
  fallback: PropTypes.string,
};
export default ImageAsyncComponent;
