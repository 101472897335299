/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import columns from './columns';

const DisplayCommandsToValidateList = ({
  tabOfCommands,
  countOfCommands,
  onPageChange,
}) => {
  const conditionalRowStyles = [
    {
      when: row => row.buyingType.toString().trim() === 'true',
      style: {
        backgroundColor: 'WhiteSmoke',
      },
    },
    {
      when: row => row.invoiceNumber.toString().trim() !== '0',
      style: {
        backgroundColor: '#ccffcc',
      },
    },
  ];

  return (
    <>
      <div className="d-flex flex-column m-3">
        <DataTable
          subHeader
          title="Validation des commandes (les lignes en grises sont des commandes en ligne)"
          data={tabOfCommands}
          columns={columns}
          pagination
          paginationComponentOptions={{ noRowsPerPage: true }}
          onChangePage={onPageChange}
          paginationTotalRows={countOfCommands}
          paginationServer
          paginationPerPage={27}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
};

DisplayCommandsToValidateList.propTypes = {
  tabOfCommands: PropTypes.array,
  countOfCommands: PropTypes.array,
  onPageChange: PropTypes.func,
};
export default DisplayCommandsToValidateList;
