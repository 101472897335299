import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from 'prop-types';
import React from 'react';
import SidebarLinks from 'components/Sidebar/SidebarLinks';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { logout } from 'actions/auth';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: '#444',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(11),
  },
}));
const BorderLinearProgress = withStyles({
  root: {
    backgroundColor: lighten('#fcd402', 0.9),
  },
  bar: {
    backgroundColor: '#fcd402',
  },
})(LinearProgress);
const Sidebar = ({ children }) => {
  const classes = useStyles();
  const isLoading = useSelector(({ loading }) => loading.loading);

  const dispatch = useDispatch();
  const handleDisconnect = () => {
    dispatch(logout());
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <div className="d-flex justify-content-between">
          <Toolbar>
            <Typography variant="h6" noWrap>
              Moncef Jouets Dashboard
            </Typography>
          </Toolbar>
          <Toolbar>
            <ListItem button onClick={handleDisconnect}>
              <ListItemIcon>
                <ExitToAppIcon className="text-white" />
              </ListItemIcon>
            </ListItem>
          </Toolbar>
        </div>
        {isLoading && <BorderLinearProgress className={classes.loader} />}
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <SidebarLinks />
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node,
};

export default Sidebar;
