/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

const columns = [
  {
    name: 'id',
    selector: 'id',
    button: true,
    cell: row => <Link to={`/edit-promotion/${row.id}`}>{row.id}</Link>,
    sortable: true,
  },

  {
    name: 'Type de remise(% ou TND)',
    selector: 'discountType',
    button: true,
    cell: row => (
      <Link to={`/edit-promotion/${row.id}`}>{row.discountType}</Link>
    ),
    sortable: true,
    style: { width: '200px' },
  },
  {
    name: 'Montant de remise (TND)',
    selector: 'discountAmount',
    sortable: true,
    style: { width: '200px' },
  },
  {
    name: 'Pourcentage de remise (%)',
    selector: 'discountPerc',
    sortable: true,
    style: { maxWidth: '50px' },
  },
  {
    name: 'Code Promotion',
    selector: 'promotionCode',
    button: true,
    cell: row => (
      <Link to={`/edit-promotion/${row.id}`}>{row.promotionCode}</Link>
    ),
    sortable: true,
    style: { minWidth: '200px' },
  },
  {
    name: 'Date debut',
    selector: 'startDate',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Date fin',
    selector: 'endDate',
    sortable: true,
  },
  {
    name: "Nombre d'utilisation",
    selector: 'numberOfUse',
    sortable: true,
  },
];

export default columns;
