/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';
import { deleteHero } from 'actions/Heroes';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const DisplayHeroesList = ({ content }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const cancel = () => {
    setIsOpen(false);
  };
  const history = useHistory();
  const handleSubmit = () => {
    dispatch(deleteHero({ selectedId }, history));
    setIsOpen(false);
  };
  const handleDeleteHero = id => () => {
    setSelectedId(id);
    setIsOpen(true);
  };

  return (
    <div className="container space-top-1 space-top-md-2 space-bottom-2 space-bottom-lg-3">
      <div className="row justify-content-center">
        {content.map(o => (
          <div className="col-lg-2" key={o.searchKey}>
            <>
              <div className="mb-3">
                <ImageAsyncComponent image={o.image} />

                <span className="d-block h3 text-center pt-4">{o.name}</span>
              </div>
              <>
                <Link
                  className="btn btn-bg-transparent text-primary "
                  to={`/edit-hero/${o.id}`}
                >
                  <EditIcon />
                </Link>
                <button
                  className="btn btn-bg-transparent text-primary "
                  type="button"
                  onClick={handleDeleteHero(o.id)}
                >
                  <DeleteIcon />
                </button>
              </>
            </>
          </div>
        ))}
      </div>
      <Modal
        className="mt-10"
        isOpen={isOpen}
        onClose
        title={ModalTitle()}
        footer={ModalFooter(handleSubmit, cancel)}
      >
        <p>Voulez vous vraiment supprimer ce Héro ?</p>
      </Modal>
    </div>
  );
};

DisplayHeroesList.propTypes = {
  content: PropTypes.array,
};
export default DisplayHeroesList;
