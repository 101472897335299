import { FETCH_MOUVS, FETCH_MOUVS_BY_ENT } from 'constants/types/mouvs';

const initialState = {
  mouvs: [],
  mouvsByEnt: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MOUVS:
      return { ...state, mouvs: payload };
    case FETCH_MOUVS_BY_ENT:
      return { ...state, mouvsByEnt: payload };
    default:
      return state;
  }
};
