/* eslint-disable react-hooks/exhaustive-deps */
import { reduxForm } from 'redux-form';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Validators from 'helpers/formValidator';
import AsyncButton from 'components/AsyncButton';
import DatePickerField from 'components/DatePickerField';
import { useSelector, useDispatch } from 'react-redux';
import resetFields from 'helpers/resetFields';

const AddPromotionForm = ({ handleSubmit }) => {
  const {
    values: { discountType },
  } = useSelector(({ form }) => form.AddPromotionForm);
  // const [isTnd, setIsTnd] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    resetFields('AddPromotionForm', ['discountAmount'], dispatch, 0);
  }, [discountType]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <InputField
          className="col-6 border-bottom"
          label="le code en lettre"
          name="codeTitle"
          validate={[Validators.required]}
        />

        <InputField
          type="number"
          label="Valeur remise en %"
          name="discountPerc"
          className="border-bottom"
          validate={[Validators.required]}
        />

        <DatePickerField
          name="startDate"
          label="Date début"
          validate={[Validators.required]}
        />
        <DatePickerField
          name="endDate"
          label="Date fin"
          validate={[Validators.required]}
        />
        <InputField
          type="number"
          label="nombre d'utilisation (-1 si illimité)"
          name="numberOfUse"
          className="border-bottom"
          validate={[Validators.required]}
        />

        <AsyncButton type="submit" text="Submit" />
      </div>
    </form>
  );
};

AddPromotionForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'AddPromotionForm',
  // enableReinitialize: true,
})(AddPromotionForm);
