/* eslint-disable react-hooks/exhaustive-deps */
import CreateHeroForm from 'containers/CreateHeroForm';
import React, { useEffect } from 'react';
import { updateHero, fetchOneHero } from 'actions/Heroes';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

const HeroUpdatePage = props => {
  const {
    params: { id },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { hero } = useSelector(({ heroesReducer }) => heroesReducer);
  const { loading } = useSelector(state => state.loading);

  useEffect(() => {
    if (id) dispatch(fetchOneHero({ id }));
  }, []);

  const handleSubmit = values =>
    dispatch(
      updateHero(
        {
          values,
        },
        history,
      ),
    );

  return (
    <div className="container space-top-1 ">
      <h3 className="h3">Update Hero</h3>
      <div className="container">
        {!loading && (
          <CreateHeroForm onSubmit={handleSubmit} initialValues={hero} />
        )}
      </div>
    </div>
  );
};

export default HeroUpdatePage;
