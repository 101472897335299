import CreateHeroForm from 'containers/CreateHeroForm';
import React from 'react';
import { addHero } from 'actions/Heroes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

const HeroCreationPage = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = values => {
    dispatch(addHero({ values }, history));
  };

  return (
    <div className="container space-top-1 ">
      <h3 className="h3">Create a new Hero</h3>
      <div className="container">
        <CreateHeroForm onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default HeroCreationPage;
