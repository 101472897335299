/* eslint-disable max-lines */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import writtenNumber from 'written-number';
import PropTypes from 'prop-types';
import moment from 'moment';
import formatPrice from 'helpers/formatPrice';
import calculateAmount from 'helpers/calculateAmount';
import calculateAmountForLetter from 'helpers/calculateAmountForLetter';
import { formatMouvsArray } from 'helpers/formatMouvsArray';
import { extractAddressFromTunisia } from 'helpers/extractAddressFromTunisia';

const FacturePage = ({ command, factureRef }) => {
  const _command = extractAddressFromTunisia(formatMouvsArray(command));

  writtenNumber.defaults.lang = 'fr';

  return (
    <div ref={factureRef} className="container py-9 pr-6 pl-6">
      <div className="facture-page" style={{ width: '100%' }}>
        <div className="header d-flex">
          <div
            className="logo-information d-flex  col-6 p-0"
            style={{ backgroundColor: 'white' }}
          >
            <div className="col-8">
              <img
                src="https://moncefjouets.com/assets/images/logo1.png"
                style={{
                  height: '75px',
                  width: '75px',
                }}
              />
              <div>MONCEF JOUETS</div>
              <div>N° de telephone : 26284898</div>
              <div>Email : moncefjouets@gmail.com </div>
              <div>5 Espace la perle bleu, route de Gammarth, KM5</div>
              <div>2076 LA MARSA </div>
              <div>TUNIS </div>
            </div>
          </div>
          <div className="fiscal-information rounded d-flex col-6 p-0">
            <div
              className="col-10 border-1 border-black"
              style={{
                border: 'solid',
              }}
            >
              <h5>{_command?.firstLastName || 'NOM CLIENT'} </h5>
              <p style={{ color: 'black' }}>
                {' '}
                {_command?.addressFacturation || 'Adresse Client '}
              </p>
              <p style={{ color: 'black' }}>
                {_command?.address?.zipCode || 'Code Postal'}
              </p>
              <div className="d-flex flex-row">
                <p className="font-weight-bold" style={{ color: 'black' }}>
                  Tél :
                </p>{' '}
                <p style={{ color: 'black' }}>
                  {_command?.telFacturation || 'N° tel'}
                </p>
              </div>
              <div className="d-flex flex-row">
                <p className="font-weight-bold" style={{ color: 'black' }}>
                  Ville :
                </p>{' '}
                <p style={{ color: 'black' }}>
                  {`${_command?.gouvLabel}, ${_command?.villeLabel}, ${_command?.cityLabel}` ||
                    'N° tel'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content d-flex flex-column">
          <h1>Facture_BL N° {_command?.invoiceNumber}</h1>
          <div
            className="table1-container d-flex justify-content-center rounded"
            style={{
              border: 'solid',
            }}
          >
            <table
              className="border-black "
              rules="all"
              style={{
                width: '95%',
              }}
            >
              <tr>
                <th>Date</th>
                <th>Numéro de commande</th>
                <th>Nom Client</th>
                <th>From</th>
              </tr>
              <tr>
                <td> {moment().format('L')}</td>
                <td>{_command?.id || 'N° commande'}</td>
                <td>{_command?.firstLastName || 'NOM CLIENT'}</td>
                <td>site web</td>
              </tr>
            </table>
          </div>
          <div
            className="table2-container mt-2 rounded d-flex justify-content-center"
            style={{
              border: 'solid',
            }}
          >
            <table
              className="border-black "
              rules="all"
              style={{
                width: '95%',
              }}
            >
              <tr>
                <th>Référence</th>
                <th>Désignation</th>
                <th>Qte</th>
                <th>Prix/Unité</th>
                <th>Prix/Unité avec promo</th>
                <th>Montant total</th>
              </tr>
              {_command?.mouvs?.map(mouv => {
                return (
                  <>
                    <tr>
                      <td>{mouv?.product?.eanId}</td>
                      <td>{mouv?.product?.title}</td>
                      <td style={{ textAlign: 'center' }}>
                        {mouv?.commandQuantity}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {formatPrice(mouv?.product?.price, 'TND')}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {mouv?.product?.hasNoDiscount === 0
                          ? formatPrice(
                              ((100 - mouv?.discountPerc) / 100) *
                                mouv?.product?.price,
                              'TND',
                            )
                          : formatPrice(mouv?.product?.price, 'TND')}
                      </td>

                      <td style={{ textAlign: 'right' }}>
                        {mouv?.product?.hasNoDiscount === 0
                          ? formatPrice(
                              ((100 - mouv?.discountPerc) / 100) *
                                mouv?.product?.price *
                                mouv?.commandQuantity,
                              'TND',
                            )
                          : formatPrice(
                              mouv?.product?.price * mouv?.commandQuantity,
                              'TND',
                            )}
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        </div>
        <div className="footer mt-3">
          <div className="signature-price mb-3 d-flex">
            <div className="col-3" style={{ border: 'solid', height: 150 }}>
              <table>
                <th className="d-flex justify-content-center">
                  CACHET ET SIGNATURE CLIENT :
                </th>
                <tr />
              </table>
            </div>
            <div className="col-3 ml-3" style={{ border: 'solid' }}>
              <table>
                <th className="d-flex justify-content-center">
                  CACHET ET SIGNATURE Direction :
                </th>
                <tr />
              </table>
            </div>
            <div className="col-6 d-flex flex-column justify-content-end text-right mr-5 mb-3">
              <h3>
                <strong>Prix Total:</strong>{' '}
                {`${formatPrice(calculateAmount(command), 'TND')}`}
              </h3>
            </div>
          </div>
          <div
            className="price-letter  rounded mb-3 mt-3"
            style={{
              border: 'solid',
              width: '100%',
            }}
          >
            <p className="pl-2 text-body pt-1">
              Arrêté la présente facture à la somme de:{' '}
              {writtenNumber(calculateAmountForLetter(command))} milimes
            </p>
          </div>
          <div className="last-footer d-flex flex-row">
            <div className="col-3">
              Rte de gammarth <br /> Matricule Fiscale:1471111H
            </div>
            <div className="col-3">
              2076 LA MARSA <br /> R.C: B01161472016
            </div>
            <div className="col-3">
              Tunis <br /> Banque : BNA
            </div>
            <div className="col-3">
              Tél: 26284898 <br /> RIB : 03135120011500620386 F
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FacturePage.propTypes = {
  command: PropTypes.object,
  factureRef: PropTypes.any,
};
export default FacturePage;
