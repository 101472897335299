import { updateDataSet, updateProducts } from 'actions/Recommendation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Home = props => {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ loading }) => loading.loading);
  const handleUpdateRecommendation = () => {
    dispatch(updateDataSet());
  };
  const handleUpdateProducts = () => {
    dispatch(updateProducts());
  };

  return (
    <div className="conta">
      <h1 className="mr-2 ml-2">
        {' '}
        Bienvenu sur le DASHBOARD de MoncefJouets :
      </h1>
      <button
        className="btn btn-primary m-2"
        type="button"
        disabled={isLoading}
        onClick={handleUpdateRecommendation}
      >
        mettre à jours les recommandations
      </button>
      <br />
      <button
        className="btn btn-primary m-2"
        type="button"
        disabled={isLoading}
        onClick={handleUpdateProducts}
      >
        mettre à jours les articles manuellement
      </button>
    </div>
  );
};

export default Home;
