import React from 'react';
import DisplayCommandsToValidateList from 'containers/DisplayCommandsToValidateList';
import CommandsFilterDate from 'containers/CommandsFilterDate';
import ToggleSwitch from 'components/ToggleSwitch';
import { useFilterCommandsToValidate } from 'hooks/useFilterCommandsToValidate';

const CommandsToValidatePage = () => {
  const {
    listCommands,
    isPayed,
    onChangePay,
    resetFilters,
    countCommands,
    isDelivery,
    onChangeDelivery,
    onChangePhone,
    isValid,
    onChangeStatus,
    status,
    handleSearch,
    onPageChange,
  } = useFilterCommandsToValidate();

  return (
    <>
      {listCommands && (
        <>
          <CommandsFilterDate onSubmit={handleSearch} />
          <div className="ml-3 row mb-1 d-flex align-items-center ">
            <div className="mr-2 col-5 mr-2">
              Afficher les clients qui ont demandé une livraison
            </div>
            <div className="mr-2 col-2">
              <i className="fas fa fa-truck" />
            </div>
            <ToggleSwitch
              Small
              id="delvieryMode"
              currentValue={isDelivery}
              onChange={onChangeDelivery}
            />
          </div>
          <div className="ml-3 row mb-1 d-flex align-items-center ">
            <div className="mr-2 col-5 mr-2">
              Afficher les clients qui n&apos;ont pas été encore contacté
            </div>
            <div className="mr-2 col-2">
              <i className="fas fa fa-phone-square" />
            </div>
            <ToggleSwitch
              Small
              id="id"
              currentValue={isValid}
              onChange={onChangePhone}
            />
          </div>
          <div className="ml-3 row mb-1 d-flex align-items-center ">
            <div className="mr-2 col-5 mr-2">
              Afficher les clients qui ont payé
            </div>
            <div className="mr-2 col-2">
              <i className="fas fa fa-cart-plus" />
            </div>
            <ToggleSwitch
              Small
              id="Payed"
              currentValue={isPayed}
              onChange={onChangePay}
            />
          </div>
          <div className="ml-3 row mb-1 d-flex align-items-center ">
            <div className="mr-2 col-5 mr-2">
              Afficher les commandes livraison en attente
            </div>
            <div className="mr-2 col-2">
              <i className="fas fa fa-hourglass-half" />
            </div>
            <ToggleSwitch
              Small
              id="status"
              currentValue={status}
              onChange={onChangeStatus}
            />
          </div>
          <div
            className="btn btn-primary rounded ml-3"
            style={{ cursor: 'pointer' }}
            onClick={resetFilters}
          >
            RESET tous les filtres
          </div>
          <DisplayCommandsToValidateList
            tabOfCommands={listCommands}
            countOfCommands={countCommands}
            isPayed
            onPageChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

export default CommandsToValidatePage;
