/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { toTitleCase } from 'helpers/toTitleCase';
import DisplayHeroesList from 'containers/DisplayHeroesList';
import { useSelector, useDispatch } from 'react-redux';
import getImage from 'helpers/getImage';
import { fetchHeroes } from 'actions/Heroes';
import AddButton from 'components/AddButton';

const HeroesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHeroes());
  }, []);

  const { heroes } = useSelector(({ heroesReducer }) => heroesReducer);

  const _heroes = [];
  const _heroesInvisible = [];

  const filteredHeroes = heroes.filter(o => o.isVisible === true);
  const filteredHeroesInvisible = heroes.filter(o => o.isVisible === false);

  filteredHeroes.map(o =>
    _heroes.push({
      name: toTitleCase(o.title1.toUpperCase()),
      image: getImage(o.image),
      id: o.id,
      searchKey: `hero=${o.title1}`,
    }),
  );

  filteredHeroesInvisible.map(o =>
    _heroesInvisible.push({
      name: toTitleCase(o.title1.toUpperCase()),
      image: getImage(o.image),
      id: o.id,
      searchKey: `hero=${o.title1}`,
    }),
  );

  return (
    <>
      (Cliquer sur le stylo pour modifier le hero y compris la visibilité)
      <h3>Héros visible pour les clients :</h3>
      <DisplayHeroesList content={_heroes} />
      <hr />
      <h3>Héros invisible pour les clients :</h3>
      <DisplayHeroesList content={_heroesInvisible} />
      <AddButton link="add-hero" />
    </>
  );
};

export default HeroesPage;
