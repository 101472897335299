/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import columns from './columns';

const DisplayTransactionsList = ({ content, onPageChange }) => {
  return (
    <DataTable
      subHeader
      title="Validation des commandes"
      data={content.allTransactions}
      columns={columns}
      pagination
      paginationComponentOptions={{ noRowsPerPage: true }}
      onChangePage={onPageChange}
      paginationTotalRows={content.count}
      paginationServer
      paginationPerPage={27}
    />
  );
};

DisplayTransactionsList.propTypes = {
  content: PropTypes.array,
  onPageChange: PropTypes.func,
};
export default DisplayTransactionsList;
