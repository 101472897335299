/* eslint-disable react/button-has-type */
import { CircularProgress, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const ColorCircularProgress = withStyles({
  root: {
    color: '#fff',
  },
})(CircularProgress);

const AsyncButton = ({ type = 'button', text, onClick }) => {
  const isLoading = useSelector(({ loading }) => loading.loading);

  return (
    <button
      onClick={onClick}
      type={type}
      className="btn btn-primary"
      disabled={isLoading}
    >
      {isLoading ? <ColorCircularProgress /> : text}
    </button>
  );
};

AsyncButton.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default AsyncButton;
