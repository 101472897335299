import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCommandsToValidate } from 'actions/CommandsToValidate';
import moment from 'moment';

export const useFilterCommandsToValidate = () => {
  const { commandsToValidate } = useSelector(
    ({ commandsToValidateReducer }) => commandsToValidateReducer,
  );
  const [isPayed, setIsPayed] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [Status, setStatus] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [listCommands, setListCommands] = useState([]);

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [page, setPage] = useState();
  const dispatch = useDispatch();
  const onPageChange = newPage => {
    setPage(newPage - 1);
  };
  const handleSearch = e => {
    setFrom(moment(e.from, 'YYYY-MM-DD').format('YYYY-MM-DD'));
    setTo(
      moment(e.to, 'YYYY-MM-DD')
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    );
  };

  useEffect(() => {
    dispatch(fetchCommandsToValidate(page, from, to));
    const fetchRepeat = setInterval(
      () => dispatch(fetchCommandsToValidate(page, from, to)),
      1000000,
    );

    return () => clearInterval(fetchRepeat);
  }, [from, to, page, dispatch]);

  useEffect(() => {
    // eslint-disable-next-line no-extra-parens
    setListCommands([...(commandsToValidate?.orders || [])]);
  }, [commandsToValidate]);
  let countCommands = 0;

  if (commandsToValidate.orders && commandsToValidate.count) {
    countCommands = commandsToValidate?.count;
  }
  const resetFilters = () => {
    setIsPayed(false);
    setIsDelivery(false);
  };
  const onChangePay = e => {
    setIsPayed(e.target.checked);
  };
  const onChangePhone = e => {
    setIsValid(e.target.checked);
  };
  const onChangeStatus = e => {
    setStatus(e.target.checked);
  };

  useEffect(() => {
    setListCommands(
      commandsToValidate?.orders?.filter(
        command =>
          (!isPayed || command.buyingType.trim() === isPayed.toString()) &&
          (!isDelivery ||
            command.deliveryMode.trim() === isDelivery.toString()) &&
          (!isValid || command.isValid === 0) &&
          (!Status || command.status.trim() === 'attente de lancement'),
      ),
    );
  }, [isPayed, isDelivery, commandsToValidate, isValid, Status]);

  const onChangeDelivery = e => {
    setIsDelivery(e.target.checked);
  };

  return {
    commandsToValidate,
    listCommands,
    countCommands,
    resetFilters,
    setIsPayed,
    isValid,
    setIsValid,
    setListCommands,
    isPayed,
    isDelivery,
    onChangePay,
    onChangePhone,
    onChangeDelivery,
    onChangeStatus,
    Status,
    onPageChange,
    handleSearch,
  };
};
