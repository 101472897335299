import AddPromtionForm from 'containers/AddPromotionForm';
import React from 'react';
import { addPromotion } from 'actions/Promotions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

const PromotionAddPage = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = values => {
    dispatch(addPromotion({ values }, history));
  };

  return (
    <div className="container space-top-1 ">
      <h3 className="h3">Create a new Hero</h3>
      <div className="container">
        <AddPromtionForm
          initialValues={{ discountType: '%' }}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default PromotionAddPage;
