/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import DisplayPromotionsList from 'containers/DisplayPromotionsList';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPromotions } from 'actions/Promotions';
import AddButton from 'components/AddButton';

const PromotionListPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPromotions());
  }, []);

  const { promotions } = useSelector(
    ({ PromotionsReducer }) => PromotionsReducer,
  );

  return (
    <>
      {promotions && <DisplayPromotionsList tabOfPromotions={promotions} />}
      <AddButton link="/add-promotion" />
    </>
  );
};

export default PromotionListPage;
