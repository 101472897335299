import { LOGIN_SUCCESS, RESET_USER } from 'constants/types/auth';

export const authInitialState = {
  loading: false,
  loaded: false,
  error: false,
  isLogged: false,
  token: false,
  isPredloaded: false,
  user: {},
};

export default (state = authInitialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        loaded: true,
        token: payload.token,
        user: payload.user,
      };
    case RESET_USER:
      return { ...state, isLogged: false, loaded: true };
    default:
      return state;
  }
};
