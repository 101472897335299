/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import DatePickerField from 'components/DatePickerField';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

const CommandsFilterDate = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex ml-3 align-items-center">
        <DatePickerField name="from" label="De" />
        &nbsp;
        {`   =>   `}
        &nbsp;
        <DatePickerField name="to" label="à" />
        <div className="ml-1">
          <label className="form-label">
            <span className="d-flex justify-content-between align-items-center" />
          </label>
          <button className="btn btn-primary" type="submit">
            Cherchez
          </button>
        </div>
      </div>
    </form>
  );
};

CommandsFilterDate.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'CommandsFilterDate',
})(CommandsFilterDate);
