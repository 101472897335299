import { Tunisia } from 'constants/Tunisia';

export const extractAddressFromTunisia = command => {
  const ourAddress = Tunisia.filter(
    element => element.govId.toString() === command?.address?.gouv,
  );

  command.gouvLabel = ourAddress[0]?.govLabel;

  const ourVille = ourAddress[0]?.villes?.filter(
    element => element.ville.toString() === command?.address?.ville,
  );

  // eslint-disable-next-line prefer-destructuring
  command.villeLabel = ourVille?.[0]?.villeName;

  const ourCity = ourVille?.[0]?.cites?.filter(
    element => element.IDN3.toString() === command?.address?.city,
  );

  command.cityLabel = ourCity?.[0]?.LIBELLEN3;

  return command;
};
