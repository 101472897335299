import {
  FETCH_CATEGORIES,
  FETCH_ONE_CATEGORY,
} from 'constants/types/categories';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { api } from 'api';

export const fetchCategories = search => async (dispatch, getState) => {
  const rootState = getState();
  const { isPreloaded } = rootState.categoriesReducer;

  if (isPreloaded) return;

  dispatch(startLoading());

  try {
    const response = await await api.get(`/categories`);

    dispatch({ type: FETCH_CATEGORIES, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const fetchOneCategory = ({ id }) => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`/categories/${id}`);

    dispatch({ type: FETCH_ONE_CATEGORY, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const updateImageCategory = ({ values }, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  const formData = new FormData();

  formData.append('image', values.image[0]);

  try {
    await api.put(`/categories/${values.id}`, formData);
    history.push('/categories');
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};
