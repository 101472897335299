import { reduxForm } from 'redux-form';
import ImagePickerField from 'components/ImagePickerField';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import AsyncButton from 'components/AsyncButton';
import SwitchBoxField from 'components/SwitchBoxField';

const CreateHeroForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="col-6">
        <InputField
          label="title1"
          name="title1"
          validate={[Validators.string]}
        />
        <InputField
          label="title2"
          name="title2"
          validate={[Validators.string]}
        />
        <InputField label="title3" name="title3" />
        <InputField label="title4" name="title4" />
        <InputField label="title5" name="title5" />
      </div>
      <SwitchBoxField label="isVisible" name="isVisible" />
      <ImagePickerField name="image" label="image" maxFiles={1} />
      <AsyncButton type="submit" text="Submit" />
    </form>
  );
};

CreateHeroForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({ form: 'CreateHeroForm', enableReinitialize: true })(
  CreateHeroForm,
);
