import { startLoading, stopLoading } from 'actions/Loading';
import { api } from 'api';
import { errorHandler } from 'helpers/errorHandler';
import {
  FETCH_HERO_SECTION,
  FETCH_ONE_HERO_SECTION,
} from 'constants/types/heroSection';

export const addHeroSection = ({ values }, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  const formData = new FormData();

  formData.append('image1', values.image1[0]);
  formData.append('image2', values.image2[0]);
  formData.append('image3', values.image3[0]);
  formData.append('link1', values.link1);
  formData.append('link2', values.link2);
  formData.append('link3', values.link3);

  try {
    await api.post(`/heroSection`, formData);
    history.push('/heroSection');
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};

export const fetchHeroSection = () => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`/heroSection`);

    dispatch({ type: FETCH_HERO_SECTION, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const updateHeroSection = ({ values }, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  const formData = new FormData();

  formData.append('image1', values.image1[0]);
  formData.append('image2', values.image2[0]);
  formData.append('image3', values.image3[0]);
  formData.append('link1', values.link1);
  formData.append('link2', values.link2);
  formData.append('link3', values.link3);

  try {
    await api.put(`/heroSection/${values.id}`, formData);
    history.push('/');
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};

export const deleteHeroSection = (
  { selectedId },
  history,
) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`/heroSection/delete/${selectedId}`);
    history.push('/');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const fetchOneHeroSection = ({ id }) => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`/heroSection/${id}`);

    dispatch({ type: FETCH_ONE_HERO_SECTION, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
