import React from 'react';
import PropTypes from 'prop-types';
import RowDetailCommand from 'components/RowDetailCommand';

const TransactionDetailPayment = ({ isOnlinePayment }) => {
  return (
    <>
      <h3 className="border-bottom">Paiement</h3>
      <div className="w-lg-100 row d-flex justify-content-center">
        <RowDetailCommand
          text="Paiement en ligne"
          value={isOnlinePayment === 'true' ? 'oui' : 'non'}
        />
      </div>
    </>
  );
};

TransactionDetailPayment.propTypes = {
  isOnlinePayment: PropTypes.string,
};

export default TransactionDetailPayment;
