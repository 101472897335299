/* eslint-disable no-nested-ternary */
import React from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { getImageUrl } from 'helpers/getImageUrl';
import formatPrice from 'helpers/formatPrice';
import PropTypes from 'prop-types';
import calculateAmount from 'helpers/calculateAmount';
import { formatMouvsArray } from 'helpers/formatMouvsArray';

const TransactionDetailMouvs = ({ command }) => {
  const _command = formatMouvsArray(command);

  return (
    <>
      <h3 className="border-bottom">Mouvement avec le stock</h3>
      <div className="w-lg-100 row d-flex justify-content-center">
        <table className="table col-10">
          <thead>
            <tr>
              <th>Id</th>
              <th> Title</th>
              <th scope="col">image</th>
              <th scope="col">quantite</th>
              <th scope="col">prix</th>
              <th scope="col">Reference</th>
              <th scope="col">Sous-Ref1</th>
              <th scope="col">Sous-Ref2</th>
              <th scope="col">Marque</th>
              <th scope="col">Qte Depot1 (Moncef Jouet)</th>
              <th scope="col">Qte Depot2 (Moncef Manar)</th>
            </tr>
          </thead>
          <tbody>
            {_command?.mouvs?.map(
              (mouv, index) =>
                mouv?.product && (
                  <>
                    <tr>
                      <td>{mouv.id}</td>
                      <td>{mouv.product.title}</td>
                      <td>
                        {mouv.reference.trim() === 'FRAITRANSPORT' ? (
                          <ImageAsyncComponent image="/assets/images/livraison.png" />
                        ) : mouv.reference.trim() === 'ZZ_TIMBRE' ? (
                          <ImageAsyncComponent image="/assets/images/timbre.jpg" />
                        ) : (
                          <ImageAsyncComponent
                            image={getImageUrl(mouv.product.eanId)}
                          >
                            {' '}
                          </ImageAsyncComponent>
                        )}
                      </td>
                      <td>{`${mouv.commandQuantity} *`}</td>
                      <td>{formatPrice(mouv.product.price, 'TND')}</td>
                      <td>{mouv.reference}</td>
                      <td>{mouv.product.SAREF1}</td>
                      <td>{mouv.product.SAREF2}</td>
                      <td>{mouv.product.brand}</td>
                      <td
                        className="text-white"
                        style={{
                          backgroundColor: 'green',
                        }}
                      >
                        {mouv.product.quantity_depot_1}
                      </td>
                      <td
                        className="text-white"
                        style={{
                          backgroundColor: 'gray',
                        }}
                      >
                        {mouv.product.quantity_depot_2}
                      </td>
                    </tr>
                  </>
                ),
            )}

            <tr>
              <td>Remise en pourcentage :</td>
              <td />
              <td />
              <td>{`- ${_command.discountPerc} % `}</td>
              <td />
            </tr>
            {/* <tr>
              <td>Remise en Dinars :</td>
              <td />
              <td />
              <td>{`- ${ _command.discountAmount} TND`}</td>
              <td />
            </tr> */}
            <tr>
              <td>Somme total de la commande :</td>
              <td />
              <td />
              <td>{`${formatPrice(calculateAmount(_command), 'TND')}`}</td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

TransactionDetailMouvs.propTypes = {
  command: PropTypes.object.isRequired,
};
export default TransactionDetailMouvs;
