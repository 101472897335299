/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import columns from './columns';

const DisplayPromotionsList = ({ tabOfPromotions }) => {
  return (
    <DataTable
      subHeader
      title="Modification des promotions"
      data={tabOfPromotions}
      columns={columns}
      // pagination
      // paginationComponentOptions={{ noRowsPerPage: true }}
      // onChangePage={onPageChange}
      // paginationTotalRows={tabOfdPromotions.count}
      // paginationServer
      // paginationPerPage={27}
    />
  );
};

DisplayPromotionsList.propTypes = {
  tabOfPromotions: PropTypes.array,
};
export default DisplayPromotionsList;
