import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { apiV2 } from 'api';

export const updateDataSet = () => async dispatch => {
  dispatch(startLoading());

  try {
    await apiV2.put(`/data-set`);
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateProducts = () => async dispatch => {
  dispatch(startLoading());

  try {
    await apiV2.put(`/es`);
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
