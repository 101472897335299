/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import DisplayHeroSectionList from 'containers/DisplayHeroSectionList';
import { useSelector, useDispatch } from 'react-redux';
import { fetchHeroSection } from 'actions/HeroSection';
import AddButton from 'components/AddButton';

const HeroSectionPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHeroSection());
  }, []);

  const { heroSections } = useSelector(
    ({ heroSectionReducer }) => heroSectionReducer,
  );

  return (
    <>
      <h3>Hero Section (les sliders dans la page accueil du site) :</h3>
      {heroSections.allHeroSection && (
        <DisplayHeroSectionList content={heroSections.allHeroSection} />
      )}
      <hr />

      <AddButton link="add-hero-section" />
    </>
  );
};

export default HeroSectionPage;
