import {
  FETCH_CATEGORIES,
  CATEGORY_STATE,
  FETCH_ONE_CATEGORY,
} from 'constants/types/categories';

const initialState = {
  categories: [],
  categoryState: '',
  category: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CATEGORIES:
      return { ...state, categories: payload };
    case FETCH_ONE_CATEGORY:
      return { ...state, category: payload };
    case CATEGORY_STATE:
      return { ...state, categoryState: payload };
    default:
      return state;
  }
};
