/* eslint-disable react-hooks/exhaustive-deps */
import CreateHeroSectionForm from 'containers/CreatHeroSectionForm';
import React, { useEffect } from 'react';
import { updateHeroSection, fetchOneHeroSection } from 'actions/HeroSection';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

const HeroSectionUpdatePage = props => {
  const {
    params: { id },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { heroSection } = useSelector(
    ({ heroSectionReducer }) => heroSectionReducer,
  );
  const { loading } = useSelector(state => state.loading);

  useEffect(() => {
    if (id) dispatch(fetchOneHeroSection({ id }));
  }, []);

  const handleSubmit = values =>
    dispatch(
      updateHeroSection(
        {
          values,
        },
        history,
      ),
    );

  return (
    <div className="container space-top-1 ">
      <h3 className="h3">Update Hero</h3>
      <div className="container">
        {!loading && (
          <CreateHeroSectionForm
            onSubmit={handleSubmit}
            initialValues={heroSection}
          />
        )}
      </div>
    </div>
  );
};

export default HeroSectionUpdatePage;
