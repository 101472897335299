/* eslint-disable react-hooks/exhaustive-deps */
import AddPromotionForm from 'containers/AddPromotionForm';
import React, { useEffect } from 'react';
import { updatePromotion, fetchPromotionById } from 'actions/Promotions';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';

const PromotionUpdatePage = props => {
  const {
    params: { id },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { promotion } = useSelector(
    ({ PromotionsReducer }) => PromotionsReducer,
  );
  const { loading } = useSelector(state => state.loading);

  useEffect(() => {
    if (id) dispatch(fetchPromotionById(id));
  }, []);

  const handleSubmit = values => {
    dispatch(
      updatePromotion(
        {
          values,
        },
        history,
      ),
    );
  };

  return (
    <div className="container space-top-1 ">
      <h3 className="h3">Update Promotion</h3>
      <div className="container">
        {!loading && promotion.promotion && (
          <AddPromotionForm
            onSubmit={handleSubmit}
            initialValues={{
              codeTitle: promotion.promotion.codeTitle,
              discountPerc: promotion?.promotion?.discountPerc,
              startDate: moment(promotion.promotion.startDate),
              endDate: moment(promotion.promotion.endDate),
              numberOfUse: promotion.promotion.numberOfUse,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PromotionUpdatePage;
