import {
  FETCH_COMMANDSTOVALIDATE,
  FETCH_COMMAND_BY_ID,
} from 'constants/types/commands';

const initialState = {
  commandsToValidate: [],
  command: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMMANDSTOVALIDATE:
      return { ...state, commandsToValidate: payload };
    case FETCH_COMMAND_BY_ID:
      return { ...state, command: payload };
    default:
      return state;
  }
};
