import {
  FETCH_PROMOTIONS,
  FETCH_PROMOTION_BY_ID,
} from 'constants/types/promotion';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { api } from 'api';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';

export const fetchPromotions = (page = 0) => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`/promotions`);

    dispatch({ type: FETCH_PROMOTIONS, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const fetchPromotionById = id => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`/promotions/by-id/${id}`);

    dispatch({
      type: FETCH_PROMOTION_BY_ID,
      payload: trimSpacesFromObject(response.data),
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const addPromotion = ({ values }, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());
  try {
    await api.post(`/promotions`, values);
    //   history.push('/heroes');
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};
export const updatePromotion = ({ values }, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());
  try {
    await api.put(`/promotions/${values.codeTitle}`, values);
    history.push('/promotions');
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};

// export const fetchOnePromotion = ({ id }) => async (dispatch, getState) => {
//   dispatch(startLoading());
//   try {
//     const response = await api.get(`/heroes/${id}`);

//     dispatch({ type: FETCH_PROMOTION_BY_ID, payload: response.data });
//   } catch (error) {
//     errorHandler(error);
//   }

//   dispatch(stopLoading());
// };

// export const updatePromotion = ({ values }, history) => async (
//   dispatch,
//   getState,
// ) => {
//   dispatch(startLoading());

//   const formData = new FormData();

//   formData.append('title1', values.title1);
//   formData.append('title2', values.title2);
//   formData.append('title3', values.title3);
//   formData.append('title4', values.title4);
//   formData.append('title5', values.title5);
//   formData.append('isVisible', values.isVisible);
//   formData.append('image', values.image[0]);

//   try {
//     await api.put(`/heroes/${values.id}`, formData);
//     history.push('/heroes');
//   } catch (error) {
//     errorHandler(error.message);
//   }

//   dispatch(stopLoading());
// };
