import { FETCH_BRANDS, FETCH_ONE_BRAND } from 'constants/types/brands';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { api } from 'api';
import { toast } from 'react-toastify';

export const fetchBrands = query => async (dispatch, getState) => {
  const rootState = getState();
  const { isPreloaded } = rootState.brandsReducer;

  if (isPreloaded) return;

  dispatch(startLoading());

  try {
    const response = await await api.get(`/brands`);

    dispatch({ type: FETCH_BRANDS, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const fetchOneBrand = ({ id }) => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`/brands/${id}`);

    dispatch({ type: FETCH_ONE_BRAND, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const updateImageBrand = ({ values }, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  const formData = new FormData();

  formData.append('image', values.image[0]);

  try {
    await api.put(`/brands/${values.id}`, formData);
    history.push('/brands');
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};
export const updateArticleVisibility = (isDisplay, brandId) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  try {
    const response = await api.post(
      `/brands/hide-display?isDisplay=${isDisplay}&brandId=${brandId}`,
    );

    toast(response, { className: 'toast-success' });
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};
