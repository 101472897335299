import { FETCH_TRANSACTIONS } from 'constants/types/transactions';

const initialState = {
  transactions: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TRANSACTIONS:
      return { ...state, transactions: payload };

    default:
      return state;
  }
};
