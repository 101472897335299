const calculateAmountForLetter = command => {
  let sumPrice = 0;

  let discount = 0;

  const mouvs = command.mouvs.filter(
    mouv =>
      mouv?.reference !== 'FRAITRANSPORT' &&
      mouv?.reference !== 'ZZ_TIMBRE' &&
      mouv?.reference !== '2005000003936',
  );

  // eslint-disable-next-line array-callback-return
  mouvs.map(mouv => {
    sumPrice += mouv?.commandQuantity * mouv?.product?.price;
    if (mouv?.product?.hasNoDiscount === 0) {
      discount =
        mouv?.discountPerc === 0
          ? mouv?.discountamount
          : discount +
            mouv?.product.price *
              mouv?.commandQuantity *
              (mouv?.discountPerc / 100);
    }
  });

  if (command.deliveryMode.toString().trim() === 'true') {
    sumPrice += 8;
  } else if (command.buyingType.toString().trim() === 'true') {
    sumPrice += 1;
  }

  return (sumPrice - discount) * 1000;
};

export default calculateAmountForLetter;
