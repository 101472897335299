import { reduxForm } from 'redux-form';
import ImagePickerField from 'components/ImagePickerField';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import AsyncButton from 'components/AsyncButton';

const CreateHeroSectionForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="col-6">
        <InputField label="link1" name="link1" validate={[Validators.string]} />
        <InputField label="link2" name="link2" validate={[Validators.string]} />
        <InputField label="link3" name="link3" validate={[Validators.string]} />
      </div>
      <div className="d-flex w-50 justify-content-between align-items-center">
        <div>
          <ImagePickerField name="image1" label="image1" maxFiles={1} />
        </div>
        <div>
          <ImagePickerField name="image2" label="image2" maxFiles={1} />
          <ImagePickerField name="image3" label="image3" maxFiles={1} />
        </div>
      </div>

      <AsyncButton type="submit" text="Submit" />
    </form>
  );
};

CreateHeroSectionForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'CreateHeroSectionForm',
  enableReinitialize: true,
})(CreateHeroSectionForm);
