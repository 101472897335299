/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import DisplayTransactionsList from 'containers/DisplayTransactionsList';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTransactions } from 'actions/Transactions';

const TransactionsPage = () => {
  const dispatch = useDispatch();
  const onPageChange = page => {
    dispatch(fetchTransactions(page - 1));
  };

  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  const { transactions } = useSelector(
    ({ transactionsReducer }) => transactionsReducer,
  );

  return (
    <>
      {transactions && (
        <DisplayTransactionsList
          content={transactions}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};

export default TransactionsPage;
