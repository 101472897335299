/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const DisplayList = ({ content, path }) => {
  return (
    <div className="container space-top-1 space-top-md-2 space-bottom-2 space-bottom-lg-3">
      <div className="row justify-content-center">
        {content.map(o => (
          <div
            className="col-lg-2 d-flex justify-content-center flex-column"
            key={o.searchKey}
          >
            <>
              <div className="mb-3">
                <ImageAsyncComponent image={o.image} />

                <span className="d-block h3 text-center pt-4">{o.name}</span>
              </div>
              <Link
                className="btn btn-sm mx-4 btn-soft-primary d-flex align-items-center justify-content-center"
                to={`${path}/${o.id}`}
              >
                Modifier
                <EditIcon style={{ transform: 'scale(0.5)' }} />
              </Link>
            </>
          </div>
        ))}
      </div>
    </div>
  );
};

DisplayList.propTypes = {
  content: PropTypes.array,
  path: PropTypes.string,
};
export default DisplayList;
