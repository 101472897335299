/* eslint-disable max-len */
// import FacebookTypes from 'constants/facebookTypes';
import LoginForm from 'containers/LoginForm';
import React from 'react';
// import SEO from 'components/SEO';
import { login } from 'actions/auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = values => {
    dispatch(login(values, history));
  };

  return (
    <>
      {/* <SEO
        title="Se connecter à El Business"
        description="la page d'accueil du site"
        type={FacebookTypes.article}
      /> */}
      <main id="content" role="main">
        <div className="d-flex align-items-center position-relative height-lg-100vh">
          {/* <AuthSidebar /> */}
          <div className="container vw-100 vh-100">
            <div className="row no-gutters">
              <div className="m-m-auto">
                <LoginForm onSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
