/* eslint-disable no-extra-parens */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import ToggleSwitch from 'components/ToggleSwitch';
import {
  changeValidValue,
  createFacture,
  insertPostion,
} from 'actions/CommandsToValidate';
import { useDispatch } from 'react-redux';
import RowDetailCommandPhone from 'components/RowDetailCommandPhone';
import PropTypes from 'prop-types';
// import CheckBoxStatus from 'components/CheckBoxStatus';
import RowDetailCommand from 'components/RowDetailCommand';
import Modal from 'components/Modal';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const ManageCommand = ({ command }) => {
  const dispatch = useDispatch();
  const [isOpenPhone, setIsOpenPhone] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedValue, setSelectedValue] = useState();

  const cancelPhone = () => {
    setIsOpenPhone(false);
    // setSwitchState(!switchState);
  };

  const handleSubmitPhone = () => {
    dispatch(changeValidValue(selectedId, selectedValue));
    setIsOpenPhone(false);
  };

  const handleClickPhone = (id, value) => {
    setSelectedId(id);
    setSelectedValue(value);
    setIsOpenPhone(true);
  };

  return (
    <div>
      <h3 className="border-bottom">Gestion de la commande :</h3>
      <div className="d-flex justify-content-center">
        <RowDetailCommandPhone
          text="Contacté par téléphone ? "
          value={command.isValid === 1 ? 'oui' : 'non'}
          columnSize="col-9"
        >
          <ToggleSwitch
            Small
            id="id"
            currentValue={command.isValid === 1}
            onChange={e => {
              if (e.target.checked) {
                handleClickPhone(command.id, 1);
              } else {
                handleClickPhone(command.id, 0);
              }
            }}
          />
        </RowDetailCommandPhone>
      </div>
      <div className="d-flex justify-content-center">
        <RowDetailCommand
          text="Status de la livraison:"
          value={command.status}
          columnSize="col-9"
        >
          {/* <CheckBoxStatus command={command} /> */}
        </RowDetailCommand>
      </div>
      <hr />
      (Boutton pour lancer automatiquement Fparcel)
      <div className="d-flex justify-content-center">
        <RowDetailCommand columnSize="col-5">
          {command?.deliveryFlag?.trim() !== 'En cours' ? (
            'Livraison deja lancée'
          ) : command.deliveryMode.trim() === 'true' ? (
            command?.transaction?.transactionStatus ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => dispatch(insertPostion(command, true))}
              >
                Lancer la livraison
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => dispatch(insertPostion(command))}
              >
                Lancer la livraison
              </button>
            )
          ) : (
            <span className="text-center m-m-auto">
              Une commande sans livaison
            </span>
          )}
        </RowDetailCommand>
        <RowDetailCommand columnSize="col-5">
          {command.picod === 1 ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => dispatch(createFacture(command))}
            >
              Créez une facture
            </button>
          ) : (
            'Facture deja créée'
          )}
        </RowDetailCommand>
      </div>
      <div className="d-flex justify-content-center" />
      <Modal
        className="mt-10"
        isOpen={isOpenPhone}
        onClose
        title={ModalTitle()}
        footer={ModalFooter(handleSubmitPhone, cancelPhone)}
      >
        <p>Voulez vous vraiment changer la status (contacté Oui ou Non) ?</p>
      </Modal>
    </div>
  );
};

ManageCommand.propTypes = {
  command: PropTypes.object.isRequired,
};
export default ManageCommand;
