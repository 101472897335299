import { FETCH_TRANSACTIONS } from 'constants/types/transactions';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { api } from 'api';

export const fetchTransactions = (page = 0) => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`/transactions?page=${page}`);

    dispatch({ type: FETCH_TRANSACTIONS, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
