/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import { updateArticleVisibility } from 'actions/Brands';
import Modal from 'components/Modal';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const DisplayBrandsList = ({ content, path }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [brandId, setBrandId] = useState('');
  const cancel = () => {
    setIsOpen(false);
  };

  const handleChangeVisibility = (visibility, brand) => () => {
    setIsOpen(true);
    setIsDisplay(visibility);
    setBrandId(brand);
  };
  const handleSubmit = () => {
    dispatch(updateArticleVisibility(isDisplay, brandId));
    setIsOpen(false);
  };

  return (
    <div className="container space-top-1 space-top-md-2 space-bottom-2 space-bottom-lg-3">
      <div className="row justify-content-center">
        {content.map(o => (
          <div
            className="col-lg-2 d-flex justify-content-center flex-column"
            key={o.searchKey}
          >
            <>
              <div className="mb-3">
                <ImageAsyncComponent image={o.image} />

                <span className="d-block h3 text-center pt-4">{o.name}</span>
              </div>
              <Link
                className="btn btn-sm mx-4 btn-soft-primary d-flex align-items-center justify-content-center"
                to={`${path}/${o.id}`}
              >
                Modifier
                <EditIcon style={{ transform: 'scale(0.5)' }} />
              </Link>
              <div
                type="button"
                className="btn"
                onClick={handleChangeVisibility(true, o.brandId)}
              >
                Afficher <VisibilityIcon />
              </div>
              <div
                type="button"
                className="btn"
                onClick={handleChangeVisibility(false, o.brandId)}
              >
                Masquer <VisibilityOffIcon />
              </div>
            </>
          </div>
        ))}
      </div>
      <Modal
        className="mt-10"
        isOpen={isOpen}
        onClose
        title={ModalTitle()}
        footer={ModalFooter(handleSubmit, cancel)}
      >
        <p>
          Voulez vous vraiment Changer la visibilité de tous les articles de
          cette marque ?
        </p>
      </Modal>
    </div>
  );
};

DisplayBrandsList.propTypes = {
  content: PropTypes.array,
  path: PropTypes.string,
};
export default DisplayBrandsList;
