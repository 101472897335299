/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { updateImageBrand, fetchOneBrand } from 'actions/Brands';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import UpdateBrandImageForm from 'containers/UpdateBrandImageForm';

const BrandUpdatePage = props => {
  const {
    params: { id },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { brand } = useSelector(({ brandsReducer }) => brandsReducer);
  const { loading } = useSelector(state => state.loading);

  useEffect(() => {
    if (id) dispatch(fetchOneBrand({ id }));
  }, []);

  const handleSubmit = values =>
    dispatch(
      updateImageBrand(
        {
          values,
        },
        history,
      ),
    );

  return (
    <div className="container space-top-1 ">
      <h3 className="h3">Update Brand</h3>
      <div className="container">
        {!loading && (
          <UpdateBrandImageForm onSubmit={handleSubmit} initialValues={brand} />
        )}
      </div>
    </div>
  );
};

export default BrandUpdatePage;
