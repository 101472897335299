import {
  FETCH_HERO_SECTION,
  FETCH_ONE_HERO_SECTION,
} from 'constants/types/heroSection';

const initialState = {
  heroSections: [],
  heroSection: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_HERO_SECTION:
      return { ...state, heroSections: payload };
    case FETCH_ONE_HERO_SECTION:
      return { ...state, heroSection: payload };
    default:
      return state;
  }
};
