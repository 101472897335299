import React from 'react';
import RowDetailCommand from 'components/RowDetailCommand';
import PropTypes from 'prop-types';

const TransitionDetailGeneralInfo = ({ command }) => {
  return (
    <>
      <h3 className="border-bottom">Informations générales :</h3>

      <div className="w-lg-100  justify-content-center">
        <div className="d-flex justify-content-center">
          <RowDetailCommand text="Id de la Commande :" value={command.id} />
          <RowDetailCommand text="Nom Prenom :" value={command.firstLastName} />
        </div>
        <div className="d-flex justify-content-center">
          <RowDetailCommand text="date :" value={command.creation} />
          <RowDetailCommand
            text="Matricule fiscale :"
            value={command.matriculeFiscale}
          />
        </div>
      </div>
    </>
  );
};

TransitionDetailGeneralInfo.propTypes = {
  command: PropTypes.object.isRequired,
};
export default TransitionDetailGeneralInfo;
