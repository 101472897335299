import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';

const SidebarLink = ({ path, name, Icon }) => {
  return (
    <Link className="text-black-50" to={path}>
      <ListItem button>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Link>
  );
};

SidebarLink.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
  Icon: PropTypes.object,
};

export default SidebarLink;
