import DashboardIcon from '@material-ui/icons/Dashboard';
import List from '@material-ui/core/List';
import React from 'react';
import SidebarLink from 'components/Sidebar/SidebarLink';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CategoryIcon from '@material-ui/icons/Category';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PaymentIcon from '@material-ui/icons/Payment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import BallotIcon from '@material-ui/icons/Ballot';

const SidebarLinks = () => {
  // const { user } = useSelector(({ users }) => users);

  return (
    <List>
      <SidebarLink path="/" Icon={DashboardIcon} name="Dashboard" />

      <>
        <SidebarLink path="/heroes" Icon={AccessibilityIcon} name="Héros" />
        <SidebarLink
          path="/heroSection"
          Icon={BallotIcon}
          name="Hero Section"
        />
        <SidebarLink path="/categories" Icon={CategoryIcon} name="Catégories" />
        <SidebarLink path="/brands" Icon={LocalOfferIcon} name="Marques" />
        <SidebarLink path="/commands" Icon={ListAltIcon} name="Commandes" />
        <SidebarLink
          path="/promotions"
          Icon={CardGiftcardIcon}
          name="Promotions"
        />
        <SidebarLink
          path="/transactions"
          Icon={PaymentIcon}
          name="Transactions en ligne"
        />
      </>
    </List>
  );
};

export default SidebarLinks;
