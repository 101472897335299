/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { toTitleCase } from 'helpers/toTitleCase';
import { useSelector, useDispatch } from 'react-redux';
import getImage from 'helpers/getImage';
import { fetchBrands } from 'actions/Brands';
import DisplayBrandsList from 'components/DisplayBrandsList';

const BrandsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBrands());
  }, []);

  const { brands } = useSelector(({ brandsReducer }) => brandsReducer);

  const _brands = brands.map(o => ({
    name: toTitleCase(o.name),
    image: getImage(o.image),
    brandId: o.brandId,
    searchKey: `brand=${o.name}`,
    id: o.id,
  }));

  return (
    <>
      <DisplayBrandsList content={_brands} path="/edit-brand" />
    </>
  );
};

export default BrandsPage;
