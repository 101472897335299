import React, { useState } from 'react';
import RowDetailCommandPhone from 'components/RowDetailCommandPhone';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { archiveStatus } from 'actions/CommandsToValidate';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Modal from 'components/Modal';
import { useHistory } from 'react-router-dom';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const ArchiveCommand = ({ command }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenArchive, setIsOpenArchive] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const cancelArchive = () => {
    setIsOpenArchive(false);
  };

  const handleSubmitArchive = () => {
    dispatch(archiveStatus(selectedId, 'archived', history));
    setIsOpenArchive(false);
  };

  const handleClickArchive = id => () => {
    setSelectedId(id);
    setIsOpenArchive(true);
  };

  return (
    <div>
      <div className="container w-75">
        <RowDetailCommandPhone
          columnSize="bg-danger text-white"
          text="Archiver la commande ? "
        >
          <button
            type="button"
            className="bg-transparent border-0"
            onClick={handleClickArchive(command.id)}
          >
            <DeleteForeverIcon
              className="text-white"
              style={{ cursor: 'pointer' }}
            />
          </button>
        </RowDetailCommandPhone>
      </div>
      <Modal
        className="mt-10"
        isOpen={isOpenArchive}
        onClose
        title={ModalTitle()}
        footer={ModalFooter(handleSubmitArchive, cancelArchive)}
      >
        <p>Voulez vous vraiment supprimer cette Commande ?</p>
      </Modal>
    </div>
  );
};

ArchiveCommand.propTypes = {
  command: PropTypes.object,
};
export default ArchiveCommand;
