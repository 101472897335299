/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { toTitleCase } from 'helpers/toTitleCase';
import DisplayList from 'components/DisplayList';
import { useSelector, useDispatch } from 'react-redux';
import getImage from 'helpers/getImage';
import { fetchCategories } from 'actions/Categories';

const CategoriesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const { categories } = useSelector(
    ({ categoriesReducer }) => categoriesReducer,
  );

  const _categories = [];

  categories.map(o =>
    _categories.push({
      name: toTitleCase(o.title.toUpperCase()),
      image: getImage(o.image),
      searchKey: `category=${o.title}`,
      id: o.id,
    }),
  );

  return <DisplayList content={_categories} path="/edit-category" />;
};

export default CategoriesPage;
