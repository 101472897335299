import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import loading from './Loading';
import categoriesReducer from './categories';
import brandsReducer from './brands';
import heroesReducer from './heroes';
import heroSectionReducer from './heroSection';
import transactionsReducer from './transactions';
import mouvsReducer from './mouvs';
import commandsToValidateReducer from './commandsToValidate';
import PromotionsReducer from './promotions';
import auth from './auth';

export default combineReducers({
  auth,
  form,
  loading,
  categoriesReducer,
  brandsReducer,
  heroesReducer,
  transactionsReducer,
  commandsToValidateReducer,
  mouvsReducer,
  PromotionsReducer,
  heroSectionReducer,
});
