import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
import PropTypes from 'prop-types';

const AddButton = ({ link }) => {
  return (
    <div className="position-fixed" style={{ right: '50px', bottom: '50px' }}>
      <Link to={link}>
        <Fab color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </Link>
    </div>
  );
};

AddButton.propTypes = {
  link: PropTypes.string,
};
export default AddButton;
