import {
  FETCH_PROMOTIONS,
  FETCH_PROMOTION_BY_ID,
} from 'constants/types/promotion';

const initialState = {
  promotions: [],
  promotion: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PROMOTIONS:
      return { ...state, promotions: payload };
    case FETCH_PROMOTION_BY_ID:
      return { ...state, promotion: payload };
    default:
      return state;
  }
};
