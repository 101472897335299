import { FETCH_HEROES, FETCH_ONE_HERO } from 'constants/types/heroes';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { api } from 'api';

export const fetchHeroes = query => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`/heroes/all`);

    dispatch({ type: FETCH_HEROES, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const fetchOneHero = ({ id }) => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const response = await api.get(`/heroes/${id}`);

    dispatch({ type: FETCH_ONE_HERO, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const addHero = ({ values }, history) => async (dispatch, getState) => {
  dispatch(startLoading());

  const formData = new FormData();

  formData.append('title1', values.title1);
  formData.append('title2', values.title2);
  formData.append('title3', values.title3);
  formData.append('title4', values.title4);
  formData.append('title5', values.title5);
  formData.append('isVisible', values.isVisible);
  formData.append('image', values.image[0]);

  try {
    await api.post(`/heroes`, formData);
    history.push('/');
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};

export const updateHero = ({ values }, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  const formData = new FormData();

  formData.append('title1', values.title1);
  formData.append('title2', values.title2);
  formData.append('title3', values.title3);
  formData.append('title4', values.title4);
  formData.append('title5', values.title5);
  formData.append('isVisible', values.isVisible);
  formData.append('image', values.image[0]);

  try {
    await api.put(`/heroes/${values.id}`, formData);
    history.push('/heroes');
  } catch (error) {
    errorHandler(error.message);
  }

  dispatch(stopLoading());
};

export const deleteHero = ({ selectedId }, history) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`/heroes/delete/${selectedId}`);
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
