import {
  FETCH_COMMANDSTOVALIDATE,
  FETCH_COMMAND_BY_ID,
} from 'constants/types/commands';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { api } from 'api';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import { toast } from 'react-toastify';

export const fetchCommandsToValidate = (
  page = 0,
  from = '1900-1-1',
  to = '2500-1-1',
) => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`/ents?page=${page}&from=${from}&to=${to}`);

    dispatch({ type: FETCH_COMMANDSTOVALIDATE, payload: response.data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const fetchCommandDetail = id => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const response = await api.get(`/ents/by-id/${id}`);

    dispatch({
      type: FETCH_COMMAND_BY_ID,
      payload: trimSpacesFromObject(response.data),
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const changeValidValue = (id, isValid) => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const response = await api.put(`/ents/change-valid`, { id, isValid });

    dispatch({
      type: FETCH_COMMAND_BY_ID,
      payload: trimSpacesFromObject(response.data),
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const changeStatusValue = (id, status) => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const response = await api.put(`/ents/change-status`, { id, status });

    dispatch({
      type: FETCH_COMMAND_BY_ID,
      payload: trimSpacesFromObject(response.data),
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const archiveStatus = (id, status, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());
  try {
    const response = await api.put(`/ents/change-status`, { id, status });

    history.push('/commands');
    dispatch({
      type: FETCH_COMMAND_BY_ID,
      payload: trimSpacesFromObject(response.data),
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const insertPostion = (command, payed) => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const response = await api.post(`/fparcelAddress`, {
      id: command.id,
      payed,
    });

    toast('livraison lancée', { className: 'toast-success' });
    dispatch({
      type: FETCH_COMMAND_BY_ID,
      payload: trimSpacesFromObject(response.data),
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const createFacture = command => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const response = await api.post(`/ents/create-facture`, {
      id: command.id,
    });

    toast('Facture Créée', { className: 'toast-success' });
    dispatch({
      type: FETCH_COMMAND_BY_ID,
      payload: trimSpacesFromObject(response.data),
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
