import PropTypes from 'prop-types';
import React from 'react';

const ModalFooter = (handleSubmit, Cancel) => {
  return (
    <>
      <button className="btn btn-primary" onClick={Cancel} type="button">
        Cancel
      </button>

      <button className="btn btn-primary" onClick={handleSubmit} type="button">
        Delete
      </button>
    </>
  );
};

ModalFooter.prototypes = {
  handleSubmit: PropTypes.func,
  CancelConnetToWifi: PropTypes.func,
};
export default ModalFooter;
