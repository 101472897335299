import { FETCH_BRANDS, FETCH_ONE_BRAND } from 'constants/types/brands';

const initialState = {
  brands: [],
  brand: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BRANDS:
      return { ...state, brands: payload };
    case FETCH_ONE_BRAND:
      return { ...state, brand: payload };
    default:
      return state;
  }
};
