import { reduxForm } from 'redux-form';
import ImagePickerField from 'components/ImagePickerField';

import PropTypes from 'prop-types';
import React from 'react';

import AsyncButton from 'components/AsyncButton';

const UpdateBrandImageForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <ImagePickerField name="image" label="image" maxFiles={1} />
      <AsyncButton type="submit" text="Submit" />
    </form>
  );
};

UpdateBrandImageForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'UpdateBrandImageForm',
  enableReinitialize: true,
})(UpdateBrandImageForm);
