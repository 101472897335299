/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

/*
Toggle Switch Component
Note: id is required for ToggleSwitch component to function. Name, currentValue, defaultChecked, Small and onChange're optional.
Usage: <ToggleSwitch id="id" onChange={function (e) { console.log("Checkbox Current State: " + e.target.checked); }} />
*/

export const ToggleSwitch = ({
  Name,
  Small,
  id,
  currentValue,
  disabled,
  defaultChecked,
  Text = ['oui', 'non'],
  onChange,
}) => {
  return (
    <div className={`toggle-switch${Small ? ' small-switch' : ''}`}>
      <input
        type="checkbox"
        name={Name}
        className="toggle-switch-checkbox"
        id={id}
        checked={currentValue}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
      />
      {id ? (
        <label className="toggle-switch-label" htmlFor={id}>
          <span
            className={
              disabled
                ? 'toggle-switch-inner toggle-switch-disabled'
                : 'toggle-switch-inner'
            }
            data-yes={Text[0]}
            data-no={Text[1]}
          />
          <span
            className={
              disabled
                ? 'toggle-switch-switch toggle-switch-disabled'
                : 'toggle-switch-switch'
            }
          />
        </label>
      ) : null}
    </div>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  Text: PropTypes.string.isRequired,
  Name: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  Small: PropTypes.bool,
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ToggleSwitch;
