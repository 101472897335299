/* eslint-disable no-extra-parens */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

const columns = [
  {
    name: 'id',
    selector: 'id',
    button: true,
    cell: row => <Link to={`/transaction/${row.id}`}>{row.id}</Link>,
    sortable: true,
  },
  {
    name: 'Prenom Nom',
    selector: 'firstLastName',
    button: true,
    cell: row => <Link to={`/transaction/${row.id}`}>{row.firstLastName}</Link>,
    sortable: true,
  },
  {
    name: 'Date',
    selector: 'creation',
    sortable: true,
  },
  {
    name: 'Adr.Facturation',
    selector: 'addressFacturation',
    sortable: true,
  },
  {
    name: 'Adr.livraison',
    selector: 'addressDelivery',
    sortable: true,
    wrap: true,
  },
  {
    name: 'Livraison ?',
    selector: 'deliveryMode',
    sortable: true,
    cell: row => (
      <div>
        {row.deliveryMode.toString().trim() === 'true' ? (
          <i className="fas fa fa-truck" />
        ) : (
          'non'
        )}
      </div>
    ),
  },

  {
    name: 'Paiement en ligne ?',
    selector: 'buyingType',
    sortable: true,
    cell: row => (
      <div>{row.buyingType.toString().trim() === 'true' ? 'oui' : 'non'}</div>
    ),
    // style: { maxWidth: '200px' },
  },
  {
    name: 'Paiement success ? ',
    selector: 'successP',
    sortable: true,
    cell: row => (
      <div>{row?.transaction?.transactionStatus ? 'oui ✅' : 'non'}</div>
    ),
  },
  {
    name: 'id transaction ?',
    selector: 'transactionId',
    sortable: true,
    cell: row => <div>{row?.transaction?.id}</div>,
  },
  {
    name: 'Téléphone',
    selector: 'telDelivery',
    sortable: true,
  },
  {
    name: 'Contacté Par tel?',
    selector: 'isValid',
    sortable: true,
    cell: row => (
      <div className={row.isValid === 1 ? 'text-success' : 'text-danger'}>
        {(row.isValid === 1).toString() === 'true' ? 'oui' : 'non'}
      </div>
    ),
  },
  {
    name: 'N° Facture?',
    selector: 'invoiceNumber',
    sortable: true,
  },
  {
    name: 'Status de livraison',
    selector: 'status',
    sortable: true,
    cell: row => (
      <div
        className={
          row.status.toUpperCase().trim() === 'LIVRÉE'.toUpperCase().trim()
            ? 'text-success'
            : row.status.toUpperCase().trim() ===
              'EN COURS'.toUpperCase().trim()
            ? 'text-warning'
            : row.status.toUpperCase().trim() ===
              'ATTENTE DE LANCEMENT'.toUpperCase().trim()
            ? 'text-gray'
            : 'text-danger'
        }
      >
        {row.status}
        {row.status.toUpperCase().trim() ===
        'ATTENTE DE LANCEMENT'.toUpperCase().trim()
          ? '...'
          : ''}
        {/* {row.status.toUpperCase().trim() === 'VALIDE' ? 'Livrée' : 'en cours'} */}
      </div>
    ),
  },
];

export default columns;
