import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';
import strings from 'constants/strings';

const LoginForm = ({ handleSubmit }) => {
  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <div className="mb-7">
        <h1 className="h3 text-primary font-weight-normal mb-0">
          {strings.welcome}
          <span className="font-weight-semi-bold"> Dashboard MoncefJouets</span>
        </h1>
        <p>{strings.signUpFormHeader}</p>
      </div>
      <InputField
        type="email"
        name="email"
        label={strings.signUpEmailLabel}
        placeholder={strings.signUpEmailPlaceholder}
        validate={[Validators.email, Validators.required]}
      />

      <InputField
        type="password"
        name="password"
        label={strings.signUpPasswordLabel}
        placeholder={strings.signUpPasswordPlaceholder}
        validate={[Validators.required, Validators.min8]}
      />

      <div className="row align-items-center mb-5">
        <div className="offset-8 col-4 text-right">
          <button className="btn btn-primary mb-1" type="submit">
            {strings.start}
          </button>
        </div>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'loginForm' })(LoginForm);
