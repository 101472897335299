import React, { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCommandDetail } from 'actions/CommandsToValidate';
import TransitionDetailGeneralInfo from 'containers/TransactionDetailGeneralInfo';
import TransactionDetailDelivery from 'components/TransactionDetailDelivery';
import TransactionDetailPayment from 'components/TransactionDetailPayment';
import TransactionDetailMouvs from 'components/TransactionDetailMouvs';
import ManageCommand from 'containers/ManageCommand';
import ArchiveCommand from 'containers/ArchiveCommand';
import FacturePage from 'pages/FacturePage';
import { useReactToPrint } from 'react-to-print';

const TransactionDetailPage = () => {
  const { params } = useRouteMatch();
  const dispatch = useDispatch();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(fetchCommandDetail(params.slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { command } = useSelector(
    ({ commandsToValidateReducer }) => commandsToValidateReducer,
  );

  return (
    <div className="m-1">
      {command.id && (
        <>
          <ManageCommand command={command} />
          <TransitionDetailGeneralInfo command={command} />
          <TransactionDetailDelivery command={command} />
          <TransactionDetailPayment isOnlinePayment={command.buyingType} />
          <TransactionDetailMouvs command={command} />
          <ArchiveCommand command={command} />
          <hr />
          <button
            className="btn btn-soft-dark mt-2 align-self-center ml-3"
            onClick={handlePrint}
            type="button"
          >
            Imprimer la facture ↓
          </button>
          <br />
          <FacturePage command={command} factureRef={componentRef} />
        </>
      )}
    </div>
  );
};

export default TransactionDetailPage;
