/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

const RowDetailCommandPhone = ({
  text,
  value,
  classNameForValue,
  columnSize,
  children,
}) => {
  let _columnSize = 'col-5';

  if (columnSize) _columnSize = columnSize;

  return (
    <>
      <div className={`card ${_columnSize}  card-frame py-3 px-4 mb-1`}>
        <div className="row d-flex justify-content-between align-items-sm-center">
          <span className="">{text}</span>

          <span className={classNameForValue}>{value}</span>
          {children}
        </div>
      </div>
    </>
  );
};

RowDetailCommandPhone.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  classNameForValue: PropTypes.string,
  columnSize: PropTypes.string,
  children: PropTypes.node,
};
export default RowDetailCommandPhone;
