import React from 'react';
import RowDetailCommand from 'components/RowDetailCommand';
import PropTypes from 'prop-types';

const TransactionDetailDelivery = ({ command }) => {
  return (
    <>
      <h3 className="border-bottom">Livraison :</h3>
      <div className="w-lg-100 row d-flex justify-content-center">
        <RowDetailCommand
          text="Une livraison :"
          value={command.deliveryMode === 'true' ? 'oui' : 'non'}
        />
        {command.deliveryMode === 'false' && (
          <RowDetailCommand
            text="Magasin livraison :"
            value={command.deliveryShop}
          />
        )}
        {command.deliveryMode && (
          <RowDetailCommand
            text="Adr de facturation :"
            value={command.addressFacturation}
          />
        )}
        <RowDetailCommand
          text="plus d'infos:"
          value={command.deliveryDescription}
        />
        <RowDetailCommand
          text="Adr de livraison :"
          value={command.addressDelivery}
        />
        <RowDetailCommand
          text="Telephone principal :"
          value={command.telFacturation}
        />
        <RowDetailCommand
          text="2em Telephone :"
          value={command?.address?.secondNumber}
        />
        <RowDetailCommand
          text="Flag de livraison:"
          value={command?.deliveryFlag}
        />
      </div>
    </>
  );
};

TransactionDetailDelivery.propTypes = {
  command: PropTypes.object.isRequired,
};
export default TransactionDetailDelivery;
