import { FETCH_HEROES, FETCH_ONE_HERO } from 'constants/types/heroes';

const initialState = {
  heroes: [],
  hero: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_HEROES:
      return { ...state, heroes: payload };
    case FETCH_ONE_HERO:
      return { ...state, hero: payload };
    default:
      return state;
  }
};
