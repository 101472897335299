/* eslint-disable react-hooks/exhaustive-deps */
import { Switch } from 'react-router-dom';
import HeroesPage from 'pages/HeroesPage';
import HeroSectionPage from 'pages/HeroSectionPage';
import CategoriesPage from 'pages/CategoriesPage';
import BrandsPage from 'pages/BrandsPage';
import HomePage from 'pages/HomePage';
import HeroCreationPage from 'pages/HeroCreationPage';
import HeroSectionCreationPage from 'pages/HeroSectionCreationPage';
import React, { useEffect } from 'react';
import Sidebar from 'components/Sidebar';
import HeroUpdatePage from 'pages/HeroUpdatePage';
import CategoryUpdatePage from 'pages/CategoryUpdatePage';
import BrandUpdatePage from 'pages/BrandUpdatePage';
import TransactionsPage from 'pages/TransactionPage';
import CommandsToValidatePage from 'pages/CommandsToValidate';
import TransactionDetailPage from 'pages/TransactionDetailPage';
import PromotionListPage from 'pages/PromotionListPage';
import PromotionAddPage from 'pages/PromotionAddPage';
import PromotionUpdatePage from 'pages/PromotionUpdatePage';
import HeroSectionUpdatePage from 'pages/HeroSectionUpdatePage';
import FacturePage from 'pages/FacturePage';
import LoginPage from 'pages/Login';
import { checkLoggingStatus } from 'actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import LoggedOutRoute from './LoggedOutRoute';

const App = () => {
  const dispatch = useDispatch();
  const { loaded } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(checkLoggingStatus());
  }, []);

  if (!loaded) return <>Loading</>;

  return (
    <div>
      <Switch>
        <LoggedOutRoute exact path="/login" component={LoginPage} />
        <Sidebar>
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/facture" component={FacturePage} />
          <PrivateRoute exact path="/heroes" component={HeroesPage} />
          <PrivateRoute exact path="/heroSection" component={HeroSectionPage} />
          <PrivateRoute exact path="/categories" component={CategoriesPage} />
          <PrivateRoute exact path="/brands" component={BrandsPage} />

          <PrivateRoute
            exact
            path="/transaction/:slug"
            component={TransactionDetailPage}
          />

          <PrivateRoute
            exact
            path="/promotions"
            component={PromotionListPage}
          />
          <PrivateRoute
            exact
            path="/transactions"
            component={TransactionsPage}
          />
          <PrivateRoute
            exact
            path="/commands"
            component={CommandsToValidatePage}
          />
          <PrivateRoute exact path="/add-hero" component={HeroCreationPage} />
          <PrivateRoute
            exact
            path="/add-hero-section"
            component={HeroSectionCreationPage}
          />
          <PrivateRoute
            exact
            path="/add-promotion"
            component={PromotionAddPage}
          />
          <PrivateRoute
            exact
            path="/edit-promotion/:id"
            component={PromotionUpdatePage}
          />
          <PrivateRoute
            exact
            path="/edit-hero/:id"
            component={HeroUpdatePage}
          />
          <PrivateRoute
            exact
            path="/edit-hero-section/:id"
            component={HeroSectionUpdatePage}
          />
          <PrivateRoute
            exact
            path="/edit-brand/:id"
            component={BrandUpdatePage}
          />
          <PrivateRoute
            exact
            path="/edit-category/:id"
            component={CategoryUpdatePage}
          />
        </Sidebar>
      </Switch>
    </div>
  );
};

export default App;
