// import { APP_INIT, LOGGED_IN, LOGGED_OUT } from 'constants/types';
import { resetAuthToken, api, db } from 'api';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { LOGIN_SUCCESS, RESET_USER } from 'constants/types/auth';

export const checkLoggingStatus = () => async dispatch => {
  dispatch(startLoading());
  try {
    const id = window.localStorage.getItem('id');

    if (!id) throw Error('disconnected');
    else {
      const { token, user } = await db
        .table('user')
        .get({ id: parseInt(id, 10) });

      await resetAuthToken(token.refreshToken);

      const newAccessToken = await refreshToken();

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: { ...token, accessToken: newAccessToken },
          user,
        },
      });
      await db.user.put({
        id,
        token: { ...token, accessToken: newAccessToken },
        user,
      });
      await resetAuthToken(newAccessToken);
    }
  } catch (error) {
    dispatch({ type: RESET_USER });
  }

  dispatch(stopLoading());
};

export const login = ({ email, password }) => async dispatch => {
  dispatch(startLoading());
  try {
    const signInData = await api.post('/users/sign-in-dashboard', {
      email,
      password,
    });

    const { token, user } = signInData.data;

    resetAuthToken(token.accessToken);
    await db.user.put({ id: user.id, token, user });

    window.localStorage.setItem('id', user.id);

    dispatch({ type: LOGIN_SUCCESS, payload: { token, user } });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const logout = () => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const {
      auth: { user },
    } = getState();

    resetAuthToken(null);
    window.localStorage.removeItem('id');

    await db.user.delete(user.id);
    dispatch({ type: RESET_USER });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const refreshToken = async () => {
  try {
    const response = await api.get('/users/refresh-token');

    return response.data;
  } catch (error) {
    return false;
  }
};
