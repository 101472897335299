/* eslint-disable no-nested-ternary */
import React from 'react';

const columns = [
  {
    name: 'id',
    selector: 'orderId',
    sortable: true,
  },
  {
    name: 'Id de la commande',
    selector: 'enteteId',
    sortable: true,
  },
  {
    name: 'Somme de la transaction',
    selector: 'amount',
    sortable: true,
    cell: row => (
      <div>
        {row.amount / 1000}
        TND
      </div>
    ),
  },
  {
    name:
      'Status de la transaction(oui si le paiement est passé , non si echec)',
    selector: 'transactionStatus',
    sortable: true,
    cell: row => (
      <div className={row.transactionStatus ? 'text-success' : 'text-danger'}>
        {row?.transactionStatus?.toString() === 'true' ? 'oui' : 'non'}
      </div>
    ),
  },
  {
    name: 'Date de la transaction',
    selector: 'transactionDate',
    sortable: true,
  },
];

export default columns;
